<template>
  <div class="min-vh-100 bg-blue-dark-500 py-2">
    <div class="pt-5 p-3 text-center">
      <img
        v-if="$isDomi"
        src="../assets/LOGO_DOMI communication.png"
        width="238px"
        alt="logo"
      />
      <img v-else :src="$appLogo" width="238px" alt="logo" />
    </div>
    <b-row
      v-if="isCommunicationValid"
      class="no-gutters justify-content-center my-5 p-1"
    >
      <b-card class="main-section w-100 border-0" no-body>
        <b-card-body class="p-4">
          <b-row
            class="no-gutters justify-content-between m-n4 p-4 bg-blue-dark-400"
          >
            <b-col v-if="condominium" class="text-white">
              <h6 class="mb-0">
                {{ condominium.name }}
              </h6>
              <h6 class="mb-0">
                {{ condominium.address }} {{ condominium.streetNumber }}
              </h6>
              <h6 class="mb-0">
                {{ condominium.zip }},
                {{ condominium.city }}
                ({{ condominium.province }})
              </h6>
            </b-col>
            <b-col class="col-auto">
              <img
                v-if="requireReadReceipt"
                src="./..//assets/conferm.svg"
                style="height: 50px"
              />
            </b-col>
          </b-row>
          <hr class="my-4 mx-n4" />
          <h3 class="text-primary font-weight-normal mb-3">
            {{ $t("communication") }}
          </h3>
          <b-row class="no-gutters justify-content-between ml-n3">
            <b-col class="col-12 col-md-6 pl-3">
              <div class="font-weight-bold">{{ `${$t("subject")}:` }}</div>
              <p>{{ subject }}</p>
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <div class="font-weight-bold">{{ `${$t("date")}:` }}</div>
              <p class="text-capitalize">
                {{ createdAt | date("DD MMMM YYYY, HH:mm") }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="hasAccessToRead" class="no-gutters ml-n3">
            <b-col class="col-12 pl-3">
              <div class="font-weight-bold">{{ `${$t("description")}:` }}</div>
              <div v-html="body"></div>
            </b-col>
            <b-col class="col-12 pl-3">
              <div class="font-weight-bold">{{ `${$t("documents")}:` }}</div>
              <b-row v-if="hasDocuments" class="no-gutters">
                <b-col
                  v-for="document in documents"
                  :key="document._id"
                  class="col-auto"
                >
                  <b-button
                    class="mr-1 mb-1"
                    size="sm"
                    @click="readFile(document)"
                  >
                    <b-icon class="text-white" icon="file-earmark-text" />
                  </b-button>
                </b-col>
              </b-row>
              <div v-else>-</div>
            </b-col>
          </b-row>
          <b-row v-else class="no-gutters justify-content-end mt-5">
            <b-col class="col-auto">
              <b-button class="text-white" @click="openConfirmationModal">
                {{ $t("button.read") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-row>
    <alert />
    <loader />
    <confirm-modal />
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import ConfigProvider from "./../plugins/config-provider";

import Alert from "../components/Base/BaseAlert";
import Loader from "../components/Base/BaseLoader";
import ConfirmModal from "../components/Base/BaseConfirmModal";
import { appMixin } from "../mixins";
export default {
  mixins: [appMixin],
  components: {
    Alert,
    Loader,
    ConfirmModal
  },
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      communication: null
    };
  },
  mounted() {
    this.retrieveCommunication();
  },
  computed: {
    isCommunicationValid() {
      return this.communication !== null;
    },
    requireReadReceipt() {
      return this.communication.readReceipt;
    },
    hasAccessToRead() {
      return !this.requireReadReceipt || this.isCommunicationRead;
    },
    isCommunicationRead() {
      return this.communication.recipients[0].status === "read";
    },
    condominium() {
      return this.communication.condominiumId;
    },
    subject() {
      return this.communication.subject;
    },
    createdAt() {
      return this.communication.createdAt;
    },
    body() {
      return this.communication.body;
    },
    documents() {
      return this.communication.documents;
    },
    hasDocuments() {
      return this.documents.length > 0;
    }
  },
  methods: {
    openConfirmationModal(userId) {
      this.$store.commit("confirm/SET_CONFIRM", {
        messageText: "confirm.readCommunicationMessage",
        confirmBtnCallback: this.readCommunication
      });
    },
    readFile(file) {
      window.open(file.url, "_blank");
    },
    async retrieveCommunication() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        const response = await this.$store.dispatch(
          "communication/retrieveCommunicationByToken",
          {
            token: this.token
          }
        );

        this.communication = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async readCommunication() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        const response = await this.$store.dispatch(
          "communication/readCommunicationByToken",
          {
            token: this.token,
            payload: this.constructToken()
          }
        );

        this.communication = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    constructToken() {
      let token = "testToken";
      const SECRET_TOKEN_KEY = ConfigProvider.value("secretTokenKey");
      if (SECRET_TOKEN_KEY) {
        const data = {
          communicationId: this.communication._id,
          recipientId: this.token
        };

        token = jwt.sign(data, SECRET_TOKEN_KEY);
      }
      return { token };
    }
  }
};
</script>

<style lang="scss">
.main-section {
  max-width: 640px;
}
</style>
